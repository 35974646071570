/* Copied from https://github.com/tamagui/tamagui/blob/master/packages/web/reset.css */
/* mimic the native viewport full width/height + flex */
body,
#root,
#__next {
  display: flex;
  flex-direction: column;
  max-height: 100svh;
  min-width: calc(100vw - (100vw - 100%)); /* reserve space for scrollbar */
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--borderColor) transparent;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

li,
select,
input,
button,
[role="slider"],
[role="listbox"] {
  outline: none;
}

.is_Fieldset {
  all: unset;
}

p,
input,
textarea,
button,
pre,
dialog {
  all: unset;
  box-sizing: border-box;
}

/* remove 'all: unset' from these element types. this breaks our date picker. */
ul,
ol,
li {
  box-sizing: border-box;
}

a.is_Button,
.is_Anchor:has(.is_Button) {
  text-decoration: none;
}
