:root ._ff-f-family{font-family:var(--f-family);}
:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-color{color:var(--color);}
:root ._ta-center{text-align:center;}
:root ._w-8537{width:85%;}
:root ._w-10037{width:100%;}
:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._fd-column{flex-direction:column;}
:root ._fg-1{flex-grow:1;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._pt-t-space-bas101{padding-top:var(--t-space-base);}
:root ._pr-t-space-bas101{padding-right:var(--t-space-base);}
:root ._pb-t-space-bas101{padding-bottom:var(--t-space-base);}
:root ._pl-t-space-bas101{padding-left:var(--t-space-base);}
@media (min-width: 801px){:root:root:root:root:root:root:root:root:root:root ._w-_gtSm_600px{width:600px;}}
:root ._ai-stretch{align-items:stretch;}
:root ._fs-0{flex-shrink:0;}
:root ._h-88px{height:88px;}
:root ._col-text{color:var(--text);}
:root ._pt-t-space-lg{padding-top:var(--t-space-lg);}
:root ._h-t-size-1{height:var(--t-size-1);}
:root ._fow-f-weight-5{font-weight:var(--f-weight-5);}
:root ._ls-f-letterSpa1360334201{letter-spacing:var(--f-letterSpacing-5);}
:root ._fos-f-size-4{font-size:var(--f-size-4);}
:root ._lh-f-lineHeigh112924{line-height:var(--f-lineHeight-5);}
:root ._h-t-size-0--753{height:var(--t-size-0--75);}
:root ._fos-f-size-3{font-size:var(--f-size-3);}