:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._pt-t-space-bas101{padding-top:var(--t-space-base);}
:root ._pr-t-space-bas101{padding-right:var(--t-space-base);}
:root ._pb-t-space-bas101{padding-bottom:var(--t-space-base);}
:root ._pl-t-space-bas101{padding-left:var(--t-space-base);}
:root ._ai-flex-start{align-items:flex-start;}
:root ._gap-t-space-xs{gap:var(--t-space-xs);}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-color{color:var(--color);}
:root ._ai-stretch{align-items:stretch;}
:root ._bg-background{background-color:var(--background);}