:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-t-space-xs{margin-bottom:var(--t-space-xs);}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-5{font-weight:var(--f-weight-5);}
:root ._ls-f-letterSpa1360334201{letter-spacing:var(--f-letterSpacing-5);}
:root ._fos-f-size-3{font-size:var(--f-size-3);}
:root ._lh-f-lineHeigh112924{line-height:var(--f-lineHeight-5);}
:root ._col-text{color:var(--text);}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ac-center{align-content:center;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._gap-t-space-xs{gap:var(--t-space-xs);}
:root ._fw-wrap{flex-wrap:wrap;}
:root ._fd-row{flex-direction:row;}
:root ._mb-0px{margin-bottom:0px;}
:root ._col-textLink{color:var(--textLink);}
:root ._ai-stretch{align-items:stretch;}
:root ._btc-borderColor{border-top-color:var(--borderColor);}
:root ._brc-borderColor{border-right-color:var(--borderColor);}
:root ._bbc-borderColor{border-bottom-color:var(--borderColor);}
:root ._blc-borderColor{border-left-color:var(--borderColor);}
:root ._fs-1{flex-shrink:1;}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-0px{border-bottom-width:0px;}
:root ._blw-0px{border-left-width:0px;}
:root ._fg-1{flex-grow:1;}
:root ._mah-initial{max-height:initial;}
:root ._h-16px{height:16px;}
:root ._w-0px{width:0px;}
:root ._maw-0px{max-width:0px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._background-textSeconda3655{background:var(--textSecondary);}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._transform-translateY0419227808{transform:translateY(0px) translateX(-0.5px);}