@font-face {
  font-family: "Fields";
  src: url("/fonts/Fields-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Fields";
  src: url("/fonts/Fields-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
